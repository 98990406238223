import axios from 'axios';
import { defineComponent } from 'vue';
import Loader from '@/core/components/ui/Loader.vue';
import StringHelper from '@/core/helpers/string.helper';
import { VUE_APP_EPC_API, } from '../../../config';
const DISPLAY = 'display';
const DOMESTIC = 'domestic';
const NON_DOMESTIC = 'non-domestic';
export default defineComponent({
    components: {
        Loader
    },
    data() {
        return {
            DISPLAY,
            DOMESTIC,
            NON_DOMESTIC,
            searchKey: '',
            addresses: [],
            selectedKey: '',
            findingAddress: false,
            loadingEpcData: false,
            epcData: null,
            hasNoEpcData: false,
            epcFilter: DOMESTIC,
            epcOptions: [{
                    value: DISPLAY,
                    label: StringHelper.capitalizeFirstLetter(DISPLAY)
                },
                {
                    value: NON_DOMESTIC,
                    label: StringHelper.capitalizeFirstLetter(NON_DOMESTIC)
                },
                {
                    value: DOMESTIC,
                    label: StringHelper.capitalizeFirstLetter(DOMESTIC)
                }],
            epcUrls: {
                display: 'https://epc.opendatacommunities.org/api/v1/display',
                domestic: 'https://epc.opendatacommunities.org/api/v1/domestic',
                'non-domestic': 'https://epc.opendatacommunities.org/api/v1/non-domestic'
            },
        };
    },
    methods: {
        onChangeFilter() {
            this.onSearchAddress();
        },
        async onSearchAddress() {
            this.addresses = [];
            if (this.searchKey.length > 3) {
                this.findingAddress = true;
                this.hasNoEpcData = false;
                this.epcData = null;
                const url = `${this.epcUrls[this.epcFilter]}/search`;
                const postcode = this.searchKey;
                await axios(`${url}?postcode=${postcode}&size=500`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Basic ${VUE_APP_EPC_API}`
                    }
                }).then((response) => {
                    this.addresses = response.data?.rows || [];
                    this.findingAddress = false;
                }).catch(() => {
                    this.$notify.error({
                        title: 'Error in finding address',
                        message: 'Please enter a valid postcode'
                    });
                });
            }
        },
        onSelectAddress(row) {
            this.loadingEpcData = true;
            this.epcData = null;
            this.hasNoEpcData = false;
            setTimeout(() => {
                if (row) {
                    this.epcData = row;
                    this.loadingEpcData = false;
                    this.hasNoEpcData = false;
                }
                else {
                    this.epcData = null;
                    this.hasNoEpcData = true;
                }
            }, 1000);
        },
    },
});
